/* eslint-disable no-unreachable */
// @flow
/* global google */
import * as React from "react";
import {GoogleMap, Marker, InfoWindow, withGoogleMap, withScriptjs} from "react-google-maps";
import {Dealer} from "./Dealer";

export type LocationType = {
	lat: number,
	lng: number
}

export type MapProps = {
	longitude: number,
	latitude: number,
	error: string,
	otherDealers: [],
	boundedDealers: [],
	googleMapURL: string,
	loadingElement: React.Node,
	containerElement: React.Node,
	mapElement: React.Node,
	openDealerShopsInNewTab: boolean,
	selectedLocation: LocationType;
}

const Map = ({otherDealers, boundedDealers, error, latitude, longitude, openDealerShopsInNewTab, selectedLocation}: MapProps) => {
	const [activeInfoWindow, setActiveInfoWindow] = React.useState(null);

    React.useEffect(() => {
        if (mapRef.current) {
            const bounds = new google.maps.LatLngBounds();
            mapRef.current.fitBounds(bounds);
        }
    }, [boundedDealers]);

    const mapRef = React.useRef();

	const getBounds = React.useCallback(() => {
		const bounds = new google.maps.LatLngBounds();
		let radius = false;
		let lastDealerDistance = null;

		if (selectedLocation && boundedDealers.length) {
			const selectedLatLng = new google.maps.LatLng(selectedLocation.lat, selectedLocation.lng);

			if (otherDealers.length > 0) {
				const lastDealer = otherDealers[otherDealers.length - 1];
				const lastDealerLatLng = new google.maps.LatLng(lastDealer.location.latitude, lastDealer.location.longitude);

				lastDealerDistance = google.maps.geometry.spherical.computeDistanceBetween(selectedLatLng, lastDealerLatLng);
				radius = lastDealerDistance <= 20000;
				bounds.extend(selectedLatLng);
			}
		}

		if (boundedDealers.length) {
			boundedDealers.forEach(dealer => {
				const dealerLatLng = new google.maps.LatLng(dealer.location.latitude, dealer.location.longitude);
				bounds.extend(dealerLatLng);

				if (radius) {
					const radiusBounds = new google.maps.Circle({
						center: dealerLatLng,
						radius: 1000,
					}).getBounds();
					bounds.union(radiusBounds);
				} else {
					const radiusBounds = new google.maps.Circle({
						center: dealerLatLng,
						radius: 20000,
					}).getBounds();
					bounds.union(radiusBounds);
				}
			});

			otherDealers.forEach(dealer => {
				new google.maps.LatLng(dealer.location.latitude, dealer.location.longitude);
			});
		}
		else if (!selectedLocation) {
			const worldBounds = new google.maps.LatLngBounds(
				new google.maps.LatLng(-10, -100),
				new google.maps.LatLng(10, 100)
			);
			bounds.union(worldBounds);
		}

		else {
			const radiusBounds = new google.maps.Circle({
				center: selectedLocation,
				radius: 20000,
			}).getBounds();
			bounds.union(radiusBounds);
		}

		return bounds;
	}, [otherDealers, boundedDealers, selectedLocation]);

	React.useEffect(() => {
		if (mapRef.current) {
			const bounds = getBounds();
			mapRef.current.fitBounds(bounds);
		}
	}, [otherDealers, selectedLocation, getBounds]);

	if (latitude || error) {
		const refButton = document.querySelector(".hpm-App .ico.search");

		return <GoogleMap
			ref={mapRef}
			defaultOptions={{
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				styles: [
					{
						featureType: "poi",
						elementType: "labels",
						stylers: [{ visibility: "off" }],
					},
				]
			}}
		>
			{(!!latitude || !!selectedLocation) && <Marker
				key={"currentLocation"}
				icon={{
					path: "M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z",
					fillColor: (refButton && getComputedStyle(refButton).getPropertyValue("--primaryColor")) || "tomato",
					fillOpacity: 1,
					strokeColor: (refButton && getComputedStyle(refButton).getPropertyValue("--primaryTextColor")) || "black",
					strokeWeight: 1,
					scale: 1.5,
					// eslint-disable-next-line no-undef
					anchor: new google.maps.Point(8, 10),
					rotation: 0
				}}
				position={{
					lat: selectedLocation ? selectedLocation.lat : latitude,
					lng: selectedLocation ? selectedLocation.lng : longitude
				}}
			/>}
			{boundedDealers.map(dealer => <Marker
				key={dealer.identifier.dealerNo}
				position={{
					lat: dealer.location.latitude,
					lng: dealer.location.longitude
				}}
				icon={{
					path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
					fillColor: (refButton && getComputedStyle(refButton).getPropertyValue("--primaryColor")) || "tomato",
					fillOpacity: 1,
					strokeColor: (refButton && getComputedStyle(refButton).getPropertyValue("--primaryTextColor")) || "black",
					strokeWeight: 1,
					scale: 2,
					// eslint-disable-next-line no-undef
					anchor: new google.maps.Point(14, 24)
				}}
				onClick={() => {
					setActiveInfoWindow(null);
					setActiveInfoWindow(dealer.identifier.dealerNo);
				}} label={""}
			>
				{activeInfoWindow === dealer.identifier.dealerNo
					? <InfoWindow>
						<Dealer dealer={dealer} openDealerShopsInNewTab={openDealerShopsInNewTab}/>
					</InfoWindow>
					: null}
			</Marker>)}
			{otherDealers.map(dealer => <Marker
					key={dealer.identifier.dealerNo}
					position={{
						lat: dealer.location.latitude,
						lng: dealer.location.longitude
					}}
					icon={{
						path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
						fillColor: (refButton && getComputedStyle(refButton).getPropertyValue("--primaryColor")) || "tomato",
						fillOpacity: 1,
						strokeColor: (refButton && getComputedStyle(refButton).getPropertyValue("--primaryTextColor")) || "black",
						strokeWeight: 1,
						scale: 2,
						// eslint-disable-next-line no-undef
						anchor: new google.maps.Point(14, 24)
					}}
					onClick={() => {
						setActiveInfoWindow(null);
						setActiveInfoWindow(dealer.identifier.dealerNo);
					}} label={""}
				>
				{activeInfoWindow === dealer.identifier.dealerNo
						? <InfoWindow>
							<Dealer dealer={dealer} openDealerShopsInNewTab={openDealerShopsInNewTab}/>
						</InfoWindow>
						: null}
			</Marker>)}
		</GoogleMap>;
	} else {
		return null;
	}
};

export default withScriptjs(withGoogleMap(Map));
