import React, { useEffect, useState } from "react";
import type { translate } from "../../../i18next";
import "./SpsCouponCode.scss";
import { toast } from "react-toastify";
import type { Coupon, SpsCustomer } from "../../../reducers/couponReducer";

type SpsCouponCodeProps = {
  t: translate,
  coupon: ?Coupon,
  couponErrorCode: ?string,
  couponSuccessCode: ?string,
  resetCoupon: () => void,
  setSpsCoupon: () => void,
  resetCouponToast: () => void,
  spsCustomer: SpsCustomer,
};

const SpsCouponCode = ({
                         coupon,
                         couponErrorCode,
                         couponSuccessCode,
                         resetCoupon,
                         resetCouponToast,
                         setSpsCoupon,
                         t,
                         spsCustomer
                       }: SpsCouponCodeProps) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (couponErrorCode) {
      toast.error(t("cart.coupon." + couponErrorCode));
      resetCouponToast();
      setLoading(false);
    }
    if (couponSuccessCode) {
      toast.success(t("cart.coupon." + couponSuccessCode));
      resetCouponToast();
      setLoading(false);
    }
  }, [coupon, couponErrorCode, resetCoupon, couponSuccessCode, resetCouponToast, t]);

    const handleChange = async e => {
        setLoading(true);
        try {
            if (e.target.checked) {
                await setSpsCoupon();
            } else {
                await resetCoupon();
            }
        } finally {
            setLoading(false);
        }
    };

    return (
	<div className={"hpm-spsCouponCodeBox"}>
		<div className={"accountPointsInfo"}>
			<div className="switch-checkbox">
				<label className="switch">
					{loading ? (
						<div className="loader"></div>
                        ) : (
	<>
		<input
            type="checkbox"
            checked={!!coupon}
            onChange={handleChange}
            className="hpm-couponButton"
        />
		<span className="sps-checkbox round"></span>
	</>
                    )}
				</label>
				<div className={"coupon-text"}>{t("cart.coupon.yourDeposit", { deposit: spsCustomer.deposit.toFixed(2).replace(".", ",") })}</div>
			</div>
			<div className="useCouponText">
				<span>{t("cart.coupon.useCoupon")}</span>
				<span>{t("cart.coupon.willRemain")}</span>
			</div>
		</div>
	</div>
  );
};

export default SpsCouponCode;
